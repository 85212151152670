import React, { useState } from "react"
import HighlightedLink from "../../HighlightedLink"
import { Grid, QAContainer, ContactContainer } from "./styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faChevronDown } from "@fortawesome/pro-light-svg-icons"

const Content = ({ data }) => {
  const [names, setNames] = useState({})

  const handleToggle = (name, value) => {
    setNames(names => ({ ...names, [name]: !value }))
  }

  return (
    <Grid>
      <QAContainer>
        {data.map(item => {
          const id = item.node.faqId

          return (
            <div key={id} onClick={() => handleToggle(id, names[id])}>
              <div>
                <span>{item.node.question}</span>
                <FontAwesomeIcon
                  icon={names[id] ? faChevronDown : faChevronRight}
                  size="lg"
                />
              </div>
              <p className={names[id] ? "" : "hidden"}>{item.node.answer}</p>
            </div>
          )
        })}
      </QAContainer>

      <ContactContainer>
        <div className="heading">Questions?</div>
        <div>Not sure which unit to order?</div>
        <div className="subheading">Call (561) 848-6227 for assistance.</div>
        <HighlightedLink to="/contact" name="Send us a Message" />
      </ContactContainer>
    </Grid>
  )
}

export default Content
