import styled from "styled-components"
import { Grid as BaseGrid } from "../../../elements/Layout/styled"

export const Grid = styled(BaseGrid)`
  grid-template-columns: 1.75fr 1fr;
  grid-template-rows: 1fr;
  align-items: start;
  grid-gap: 50px;

  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }
`

export const QAContainer = styled.div`
  @media screen and (max-width: 1023px) {
    order: 1;
  }

  & > div {
    border: 1px solid #ddd;
    padding: 15px;
    cursor: pointer;
    margin-bottom: 15px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--darkblue);

      & > span {
        font-weight: 600;
        font-size: 1.15;
      }
    }

    & > p {
      margin-top: 10px;
      margin-bottom: 0;
    }

    & > p.hidden {
      display: none;
    }
  }
`

export const ContactContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 15px 15px 25px 15px;
  border: 5px solid var(--green);

  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 2%;
    border: none;
  }

  & > div {
    text-align: center;
    margin: 10px 0;
  }

  & > div:first-child {
    margin-top: 0;
  }

  & > div:last-child {
    margin-bottom: 0;
  }

  & > div.logo img {
    max-width: 250px;
    height: auto;
  }

  & > div.heading {
    font-size: 36px;
    line-height: 36px;
    font-weight: 600;
    color: #154d7c;

    @media screen and (max-width: 1240px) {
      font-size: 28px;
      line-height: 28px;
    }
  }

  & > div.subheading {
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    color: #154d7c;

    @media screen and (max-width: 1240px) {
      font-size: 18px;
      line-height: 18px;
    }
  }

  & > div.disclaimer {
    font-size: 14px;
    line-height: 16px;
  }
`
