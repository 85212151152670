import React from "react"
import { graphql } from "gatsby"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Content from "../components/content/Faqs"
import Footer from "../components/Footer"
import { Main, MainContainer } from "../elements/Layout/styled"

const Faqs = ({ data }) => {
  return (
    <Layout>
      <Helmet
        name="FAQs"
        description="Our FAQs page can help answer many commonly asked questions. Mechancial AC wants to make purchasing your new ac unit as easy as possible, please call (561) 848-6227 for assistance with your order."
        slug="faqs"
      />
      <Header />
      <PageHdr title="Frequently Asked Questions" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              <Content data={data.one.edges} />
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Faqs

export const query = graphql`
  query Faqs {
    one: allACfaqs {
      edges {
        node {
          faqId
          question
          answer
        }
      }
    }
  }
`
